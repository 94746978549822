.hero {
  position: relative;
  height: 615px;
  background-position: center center;
  background-size: cover;
  text-align: center;

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    z-index: 0;
  }

  &-content {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    z-index: 1;
  }

  &-heading {
    font-family: "Brandon Grotesque", sans-serif;
    font-size: 80px;
    line-height: 80px;
    font-weight: bold;
  }

  &-subheading {
    margin-top: 10px;
    font-size: 25px;
    line-height: 32px;
  }

  &-actions {
    margin-top: 50px;
    a{
      font-size: 20px;
      line-height: 29px;
      font-family: $secondary-font-family;
      padding: 10px 25px;
    }

    i{
      font-size: 20px;
    }
  }

  &-trustpilot{
    height: 65px;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #2e2c2f;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 1;
  }
}

$sm-breakpoint: map-get($grid-breakpoints, sm);

@media screen and (max-width: $sm-breakpoint) {
  .hero {
    &-content {
      align-items: center;
    }

    &-heading {
      font-size: 64px;
      line-height: 64px;
      text-align: center;
    }

    &-subheading {
      text-align: center;
    }

    &-trustpilot{
      margin-left: 0;
      margin-right: 0;
      left: auto;
      right: auto;
    }
  }
}
