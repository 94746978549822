.page-heading{
  background-position: 0 21%;
  background-size: cover;
  position: relative;
  &-overlay{
    background: $dark;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: 1;
  }

  .container{
    position: relative;
    z-index: 2;
  }

  &-title{
    font-family: $secondary-font-family;
    font-size: 40px;
    font-weight: bold;
    line-height: 57px;
  }

  &-description{
    font-family: $primary-font-family;
    font-size: 15px;
    font-weight: normal;
    line-height: 19px;
    max-width: calc(100% - 415px);

    p:not(:last-child){
      margin-bottom: 15px;
    }
    p:last-child{
      margin-bottom: 0;
    }
  }

  &-brand{
    position: absolute;
    right: 0;
    top: 50px;
    height: 116px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: end;

    img{
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}


@media (max-width: 768px) {
  .page-heading{
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
    &-title{
      font-size: 32px;
      font-weight: bold;
      line-height: 46px;
    }
  }
}

@media (max-width: 991px){
  .page-heading{
    &-description{
      max-width: 100%;
    }

    &-brand{
      display: none;
    }
  }
}