.checkout-cart {
  background-color: #F8F8F8;

  &-header {
    i {
      font-size: 30px;
    }
  }

  &-price {
    font-size: 20px;
    line-height: 29px;
  }

  &-submit {
    border: 1px solid $dark;
    border-radius: 38px;
    padding: 9px 30px;
    background-color: $orange;
    color: $dark;
    font-size: 20px;
    line-height: 29px;
    font-family: $secondary-font-family;
    width: 100%;

    &:hover{
      background-color: $dark;
      color: $white;
    }

    &:disabled {
      background-color: $orange;
      opacity: 0.3;
      color: #2E2C2F;

      &:hover {
        background-color: #C0BFC0;
        color: $white;
        border-color: #C0BFC0;
        opacity: 1;
      }
    }
  }
}

.dropdown-select{
  margin-top:10px;
  position: relative;
  select{
    width: 100%;
    padding:12px;
    font-size: 18px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    cursor: pointer;
  }

  i{
    pointer-events: none;
    font-size: 20px;
    position: absolute;
    right: 12px;
    top:26px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #495057;
  }
}