.search-sidebar {
  width: 550px !important;
  background-color: #F8F8F8 !important;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 30px 20px;
    i {
      font-size: 30px;
      line-height: 36px;
      color: $dark;
    }
  }

  &-body {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0;
  }

  &-item{
    display: flex;
    justify-content:space-between;
    font-size: 20px;
    font-family: $secondary-font-family;
    line-height: 29px;
    margin-bottom: 10px;

    a{
      text-decoration: none;
    }
  }

  &-input{
    border:1px solid $dark;
    border-radius: 24px !important;
    padding: 12px 30px;
    font-family: $primary-font-family;
    font-size: 18px;
    line-height: 27px;
    box-shadow: none !important;
  }

  input[type="search"]::-webkit-search-cancel-button {
    display: none;
  }

  .clear-icon {
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: none;
    font-size: 20px;
    color: $dark;
    z-index: 10;
  }

  input[type="search"]:not(:placeholder-shown) + .clear-icon {
    display: block;
  }
}

#search-result{
  font-family: "Brandon Grotesque", sans-serif;
  .medium-label{
    font-weight: bold;
    font-size: 25px;
    line-height: 36px;
    margin-bottom: 10px;
    &:first-child{
      padding-top:0!important;
      border-top:0!important;
    }
  }
}