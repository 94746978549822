textarea.form-control {
  border-radius: 0!important;
  width: 100%;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(46, 44, 47);
  border-image: initial;
  padding: 12px;
  font-family: Lora, "Helvetica Nue", "Helvetica sans-serif";
}