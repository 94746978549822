:root {
  font-size: 15px;
}

.font-secondary{
  font-family: $secondary-font-family;
}

.font-primary{
  font-family: $primary-font-family;
}

.fc-white{
  color:$white;
}

.fc-dark{
  color:$dark;
}

.fc-orange{
  color:$orange;
}

.fc-red{
  color:$red;
}

.fc-green{
  color:$green;
}

.text-bg-red{
  background-color:#D10000;
}

.more-link, .less-link{
  color:inherit;
}

.container{
  --ok-gutter-x: 30px;
}

.cursor-pointer{
  cursor: pointer;
}

.only-mobile{
  display: none;
}

.only-desktop{
  display: block;
}

@media (max-width: 768px) {
  .pnotify {
    right: 0 !important;
    top: 0 !important;

    box-sizing: border-box;
  }

  .only-mobile{
    display: block;
  }

  .only-desktop{
    display: none;
  }
}

.fslightbox-source{
  background: #eee;
}

.notify-card{
  color:#2e2c2f;
  background: #ccffd5;
  font-weight: bold;
  font-family: "Brandon Grotesque", sans-serif;

  &__orange{
    background: #FDDAB2;
    color: #2E2C2F;
  }
}

.text-bold{
  font-weight: bold;
}

.text-medium{
  font-size: 25px;
  line-height: 36px;
}

.text-hover-underline:hover{
  text-decoration: underline!important;
}

.btn-clear-icon{
  display: inline-flex;
  gap: 15px;
  align-items: center;
  padding: 10px 23px 10px 30px;
  border: 1px solid #2e2c2f;
  background-color: #fff;
  color: #2e2c2f;
  font-size: 20px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  font-family: "Brandon Grotesque", sans-serif;
  &:hover{
    background: #2e2c2f;
    color: #fff;
  }
}