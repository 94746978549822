.checkout {
  &-login {
    display: flex;
    align-items: center;
    background-color: #FDDAB2;

    a {
      font-size: 20px;
      font-family: $secondary-font-family;
      line-height: 29px;
    }
  }

  &-tab {

    &-header {
      font-family: $secondary-font-family;
      font-size: 25px;
      line-height: 36px;
      color: $white;
      background-color: #475B63;
      margin-bottom: 10px;
      font-weight: bold;

      &.active{
        margin-bottom: 30px;
      }
    }

    &-body{
      &-item{
        margin-bottom: 15px;
        position: relative;

        p{
         font-size: 18px;
          line-height: 27px;
          margin-bottom: 0;
        }

        label{
          font-family: $secondary-font-family;
          font-size: 20px;
          line-height: 29px;
          font-weight: bold;
          color: $dark;
          opacity: 1;
          width: 100%;
          margin-bottom:0;
        }

        input[type="text"], input[type="email"], input[type="date"]{
          width: 100%;
          border: 1px solid $dark;
          margin-bottom: 5px;
          border-radius: 0;
          height: 45px;
          font-family: $secondary-font-family;
          font-size: 20px;
          font-weight: bold;
        }

        input[type="date"]::-webkit-calendar-picker-indicator {
          background: transparent;
          bottom: 0;
          color: transparent;
          cursor: pointer;
          height: auto;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: auto;
          z-index: 2;
        }

        .date-picker-icon {
          font-size: 20px;
          position: absolute;
          right: 10px;
          top: 60%;
          transform: translateY(-50%);
          cursor: pointer;
          color: #495057;
        }

        input[type="checkbox"]{
          border: 2px solid $dark;
        }
      }
    }
  }

  &-submit{
    padding: 7px 30px;
    border: 1px solid $dark;
    border-radius: 38px;
    font-family: $secondary-font-family;
    font-size: 20px;
    font-weight: bold;
    line-height: 29px;

    &:hover{
      background-color: $dark;
      color: $white;
      border-color: $dark;
    }
  }

  &-complete{
    p{
      font-size: 20px;
      line-height: 25px;
      font-family: $primary-font-family;
    }

    &-cart{
      &-description {
        display: flex;
      }

      &-image {
        margin-right: 15px;
        padding: 20px;
        background: #F8F8F8;
        height: 121px;
        width: 121px;

        img {
          display: flex;
          height: 100%;
          width: 100%;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}