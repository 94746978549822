.statistics-table{

  &-header{
    font-family: $secondary-font-family;
    font-size: 20px;
    line-height: 29px;
    font-weight: bold;
  }

  &-body{
    font-family: $primary-font-family;
    font-size: 18px;
    line-height: 27px;
    font-weight: normal;
  }

  &-body-item{
    font-family: $primary-font-family;
    font-size: 18px;
    line-height: 27px;
    font-weight: normal;
  }
}

@media (max-width: 1200px) {
  .statistics-table {
    overflow: auto;
   &-container{
     width: auto;
     min-width: 568px;
   }
  }
}