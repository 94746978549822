.order-table{
  width: 100%;
  font-family: "Brandon Grotesque", sans-serif;


  thead{
    font-size: 20px;
  }

  .order-details{
    width: 320px;
    padding-right: 30px;

    h5{
      margin-bottom: 10px;
    }
  }


  tbody td{
    padding-top:20px;
    padding-bottom: 20px;
    font-family: "Brandon Grotesque", sans-serif;
    vertical-align: top;
  }
}


@media all and (max-width: 992px){

  .order-table {
    thead{ display: none;}
    tr:first-child{
      border-top: 1px solid;
    }
    tr td{
      display: block;
      width: 100% !important;

      &:not(.order-details){
        padding: 2px 0;
      }

      &.order-track, &.order-receipt{
        display: inline-block;
      }
    }
  }
}