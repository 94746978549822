.filter {
  &-bar {
    &-filters {
      button {
        font-size: 20px;
        font-family: $secondary-font-family;
      }
    }

    &-results {
      font-size: 20px;
      font-family: $secondary-font-family;
      font-weight: bold;
      line-height: 24px;
    }

    &-sort {
      .dropdown-toggle{
        &:after{
          display: none;
        }
      }
      a {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-family: $secondary-font-family;
        font-weight: bold;
        line-height: 24px;
        text-decoration: none;
        justify-content: end;

        i {
          color: $dark;
          font-size: 20px;
        }
      }
    }
  }
}


@media (max-width: 768px) {
  .filter {
    &-bar {
      &-filters {
        order: 2;
        padding-top: 20px;
      }

      //&-results {
      //  order: 1;
      //  width: 100%;
      //  display: flex;
      //  justify-content: center;
      //  padding-bottom: 20px;
      //  border-bottom: 1px solid;
      //}

      //&-sort {
      //  order: 3;
      //  padding-top: 20px;
      //}
    }
  }

  .btn-filters{
    padding: 10px 20px;
  }
}
